<template>
  <div class="container">
    <div class="user-profile">
      <div class="row">
        <div class="col-12 col-md-4 user-sidebar">
          <div class="content">
            <div class="user-card">
              <div class="avatar">
                <img :src="user.avatar.url">
              </div>
              <h1>{{user.display_name || 'sem nome'}}</h1>

              
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8">
          <div class="user-content">
            

          
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerSingle",
  computed: {},
  data() {
    return {
      user: {
        name: "",
        nickname: "",
        display_name: ""
      },
    };
  },
  computed: {
  },
  methods: {
    initialize() {
      this.$http.get(`players/${this.$route.params.id}.json`).then(response => {
        this.user = response.data;
      });
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    },
  },
  components: {
  }
};
</script>

<style lang="sass" scoped>
.user-profile
  // background: #dedede
  // min-height: 800px

  .user-sidebar
    .content
      padding: 20px
    .user-card
      .avatar
        width: 128px
        height: 128px
        overflow: hidden
        border-radius: 250%
        position: relative
        border: 5px solid #ccc
        margin-bottom: 20px
        img
          width: 100%
          height: 100%
      h1
        font-weight: bold
  
  .user-content
    padding: 20px 0
    h3
      font-weight: bold
      color: #ccc
    
    .collection-list
      .collection-item
        display: block
        &:not(:last-of-type)
          border-bottom: 1px solid #e1e1e1
          margin-bottom: 20px
        a
          display: block
          font-size: 20px
          font-weight: bold
          color: #111
          transition: all .3s
          &:hover
            text-decoration: none
            color: $link
        span.date
          font-size: 12px
          display: block
        pre
          display: block
          font-size: 12px

</style>
